import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const getAllExercises = createAsyncThunk('exercise/all', async (_, { rejectWithValue }) => {
    try {
        const response = await connection.get('/exercises');
        return response.data.results;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Fetch');
    }
});

export const getAllMuscles = createAsyncThunk('exercise/muscle', async (_, { rejectWithValue }) => {
    try {
        const response = await connection.get('/exercises/muscles');
        return response.data.results;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Fetch');
    }
});

export const getAllTypes = createAsyncThunk('exercise/type', async (_, { rejectWithValue }) => {
    try {
        const response = await connection.get('/exercises/type');
        return response.data.results;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Fetch');
    }
});

import Close from 'icons/close';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slider';
import { AppDispatch, RootState } from 'redux/store';
import { getAllExercises, getAllMuscles, getAllTypes } from '../../redux/actions/exerciseActions';
import { getAllWorkouts } from '../../redux/actions/workoutActions';

export type FilterModalProps = { modalOpen; page; take; debouncedSearch; setModalOpen: (open) => void };

const FilterModal = ({ modalOpen, page, take, debouncedSearch, setModalOpen }: FilterModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { muscles, types, exercises, loading: loadingExercises } = useSelector((state: RootState) => state.exercise);
    const [minDuration, setMinDuration] = useState(0);
    const [maxDuration, setMaxDuration] = useState(500);
    const [bodyPart, setBodyPart] = useState([]);
    const [type, setType] = useState<string>();
    const [name, setName] = useState<string>();

    useEffect(() => {
        dispatch(getAllMuscles());
        dispatch(getAllTypes());
        dispatch(getAllExercises());
    }, []);

    const handleCloseFilter = useCallback(() => {
        setModalOpen(false);
        dispatch(
            getAllWorkouts({
                page,
                take,
                search: debouncedSearch,
                min_duration: minDuration,
                max_duration: maxDuration,
                categories: bodyPart,
            })
        );
    }, [bodyPart, debouncedSearch, dispatch, maxDuration, minDuration, page, take]);

    return (
        <div className={`modal ${modalOpen ? 'modal-open' : ''}`}>
            <div className="modal-box border border-secondary">
                <button onClick={() => setModalOpen(false)} className="absolute right-2 top-2 p-2">
                    <Close />
                </button>
                <h3 className="text-center text-lg font-bold">Filter Workouts</h3>
                <div className="mt-4">
                    <label className="flex w-full justify-between">
                        <span>Duration Range</span>
                        <span>
                            {minDuration} - {maxDuration}
                        </span>
                    </label>
                    <Slider
                        className={'flex h-6 items-center'}
                        thumbClassName="bg-white w-6 h-6 rounded-full border-2 border-secondary"
                        defaultValue={[0, 500]}
                        value={[minDuration, maxDuration]}
                        onChange={(value) => {
                            setMinDuration(value[0]);
                            setMaxDuration(value[1]);
                        }}
                        renderThumb={({ key, ...props }, state) => <div key={key} {...props}></div>}
                        minDistance={10}
                        renderTrack={({ key, ...props }, state: { index }) => (
                            <div
                                key={key}
                                {...props}
                                className={`h-1 rounded-full ${state.index === 1 ? 'bg-secondary' : 'bg-neutral-content'}`}
                            />
                        )}
                        min={0}
                        max={500}
                        step={10}
                    />
                </div>
                <div className="mt-4">
                    <label>Body Part</label>
                    <div className="grid grid-cols-4 gap-2">
                        {loadingExercises
                            ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((val) => (
                                  <div key={val} className="skeleton h-6 w-12 bg-secondary/20"></div>
                              ))
                            : muscles.map((muscle, index) => (
                                  <button
                                      key={index}
                                      onClick={() => {
                                          if (bodyPart.includes(muscle)) {
                                              setBodyPart(bodyPart.filter((item) => item !== muscle.name));
                                          } else {
                                              setBodyPart([...bodyPart, muscle]);
                                          }
                                      }}
                                      className={`btn btn-sm textarea-xs rounded-full ${
                                          bodyPart.includes(muscle) ? 'btn-secondary' : 'btn-outline'
                                      }`}
                                  >
                                      {muscle.name}
                                  </button>
                              ))}
                    </div>
                </div>
                <div className="mt-4 flex flex-col">
                    <label>Exercise Type</label>
                    <select onChange={(e) => setType(e.target.value)} className="input">
                        <option value={null}>All</option>
                        {types.map((type, index) => (
                            <option value={type.name}>{type.name}</option>
                        ))}
                    </select>
                </div>
                <div className="mt-4 flex flex-col">
                    <label>Exercise Name</label>
                    <select onChange={(e) => setType(e.target.value)} className="input">
                        <option value={null}>All</option>
                        {exercises.map((exercise, index) => (
                            <option value={exercise.name}>{exercise.name}</option>
                        ))}
                    </select>
                </div>
                <div className="modal-action">
                    <button onClick={handleCloseFilter} className="btn btn-outline btn-secondary">
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;

import { Clock, Fire } from 'icons';
import Close from 'icons/close';
import Filter from 'icons/filter';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { getAllWorkouts } from '../../redux/actions/workoutActions';
import FilterModal from './FilterModal';

const WorkoutsLibrary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { allWorkouts, loading } = useSelector((state: RootState) => state.workouts);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [modalOpen, setModalOpen] = useState(false);

    const handleSelectElement = useCallback(
        (element: any) => {
            navigate('/workout/' + element.id);
        },
        [navigate]
    );

    const handleOpenFilter = useCallback(() => {
        setModalOpen(true);
    }, [dispatch]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        dispatch(getAllWorkouts({ page, take, search: debouncedSearch }));
    }, [dispatch, page, take, debouncedSearch, allWorkouts.length]);

    return (
        <div className="mb-16 w-full overflow-y-scroll md:h-5/6 lg:mb-0">
            <div className="z-20 flex h-1/6 items-center justify-center bg-black p-4">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input input-bordered w-full rounded-full border p-2"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-2 top-3">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
                <button
                    onClick={handleOpenFilter}
                    className="shadow-glow ml-2 flex items-center rounded-full border-secondary bg-primary px-4 py-2 text-secondary"
                >
                    <Filter />
                    <span className="ml-2">Filter</span>
                </button>
                <FilterModal
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    debouncedSearch={debouncedSearch}
                    page={page}
                    take={take}
                />
            </div>
            <div className="grid h-5/6 grid-cols-2 gap-4 overflow-y-scroll p-2 pb-16 lg:grid-cols-4">
                {loading
                    ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((val) => (
                          <div key={val} className="skeleton h-72 w-full bg-secondary/20"></div>
                      ))
                    : allWorkouts.map((workout, index) => (
                          <div
                              onClick={() => handleSelectElement(workout)}
                              key={index}
                              className="card relative flex h-72 cursor-pointer items-center justify-end rounded border border-neutral-800 p-2 hover:border-secondary"
                              style={{
                                  backgroundImage: `url(${workout.cover_image})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'top',
                              }}
                          >
                              <div className="absolute inset-0 z-0 h-full w-full bg-gradient-to-t from-black to-transparent to-50%"></div>
                              <p className="z-10 p-4 text-center capitalize">{workout.title}</p>
                              <div className="card-actions flex-col items-center justify-end">
                                  <div className="z-10 flex items-center space-x-2 rounded-full border border-white bg-black/30 px-2">
                                      <div className="flex items-center justify-center">
                                          <Fire />
                                          <span className="text-xs">Difficulty: x {workout.difficulty}</span>
                                      </div>
                                      <div className="flex items-center">
                                          <Clock />
                                          <span className="text-xs">{workout.workout_duration % 60} min</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      ))}
            </div>
        </div>
    );
};

export default WorkoutsLibrary;
